/* Main Styles */
/* * {
  border: 1px solid red !important;
} */

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: var(--neutral-black);
  background-image: url('images/bg-gradient.svg');
  background-size: cover;
  background-attachment: fixed;
  color: var(--neutral-white-1);
  font-family: 'Proxima Thin', sans serif;
  max-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
}

section.section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
  padding: 10% 20%;
  /* gap: 0.5rem; ich weiß nicht mehr warum das war */
  box-sizing: border-box;

  justify-content: center;
}

article {
  margin: 0;
  width: 100%;
}

figure {
  margin-block: 0;
  margin-inline: 0;
}

ul {
  margin-block: 0;
  padding-inline-start: 0;
  list-style-type: none;
}

li {
  list-style-type: none;
}

/* Links */
a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h2.section-headline {
  margin-bottom: 2rem;
}

h4, h5 {
  margin-bottom: 1rem;
}

h2.section-headline.right,
h3.right {
  text-align: end;
}

p.text {
  margin-bottom: 1rem;
}

button.block {
  margin-block: 1.5rem;
}

#home {
  align-items: center;
}

section.logo a img {
  width: 6rem;
  height: auto;
}

strong {
  font-weight: bold;
}

/* Prototype */
section.prototype-content {
  align-items: center;
  gap: 4rem;
}

iframe {
  border-radius: 0.5rem;
}

/* Other Styles */
.highlighted {
  text-shadow: var(--t-glow-green-medium);
}