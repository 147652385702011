/* Aside Styles */

aside {
    position: fixed;
    top: 35vh;
    right: 3.125rem;
}

.main-nav.open {
    display: none;
}

aside ul.social-icons {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

aside ul li a svg {
    width: 2.5rem;
    height: auto;
    stroke-width: 0.05rem;
    filter:var(--s-glow-green);
}

