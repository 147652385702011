.tools-text-container {
    width: 30%; 
    display: flex;
    flex-direction: column;
}

.tools-box-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 3.125rem;
    column-gap: 3.125rem;
    flex-wrap: wrap;
    padding-inline: 10%;
    width: 100%;
}

.tools {
    justify-content: center;
    align-items: flex-start;
    gap: 5rem;
    display: flex;
}

.tool-box {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    flex-shrink: 0;
}

.tool-icon {
    width: 5rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 0.0625rem solid var(--secondary-green-2);
    border-radius: 1rem;
    box-shadow: var(--b-glow-green-light);
}

.tool-label {
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
}
