
.skills-items {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: stretch;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.pill {
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    border: 0.075rem solid var(--transparent-white-2);
    background-color: var(--transparent-black);
}

article.pill {
    width: max-content;
}

.all {
    letter-spacing: 0.16rem;
    text-transform: uppercase;
}