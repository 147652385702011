/* Component Styles */

/* Buttons */
/* general */
button,
a.button {
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
}

/* size */
button.small,
a.button.small {
    border-radius: 0.5rem;
    display: flex;
    padding-block: 0.75rem;
    padding-inline: 1.25rem;
    justify-content: center;
    align-items: center;
    min-width: 4rem;
    min-height: 2rem;
}

button.medium,
a.button.medium {
    height: 2.5rem;
    border-radius: 0.5rem;
    display: flex;
    padding-block: 1.5rem;
    padding-inline: 1.5rem;    
    justify-content: center;
    align-items: center;
    min-width: 5rem;
}

button.big,
a.button.big {
    height: 3rem;
    border-radius: 0.5rem;
    display: flex;
    padding-block: 1.75rem;
    padding-inline: 1.8rem;
    justify-content: center;
    align-items: center;
    min-width: 8rem;
}

/* style */
button.primary,
a.button.primary {
    color: var(--neutral-black);
    background: var(--secondary-green-2);
    box-shadow: var(--b-glow-green-light);
    font-weight: 800;
}

button.secondary,
a.button.secondary {
    border: 0.07rem solid var(--neutral-white-1);
    box-shadow: var(--b-glow-green-light);
    text-shadow: var(--t-glow-green-medium);
}

/* Scroll to Top Button */
.scroll-up-button {
    position: fixed;
    bottom: 15rem;
    right: 3rem;
    border: 0.09rem solid var(--neutral-white-1);
    box-shadow: var(--b-glow-green-light);
    text-shadow: var(--t-glow-green-medium);
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s linear 0.5s;
}

.scroll-up-button.show {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

button .arrow-up {
    font-size: 2rem;
}

/* Filterbuttons */
.filter {
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
}

.filter.active {
    font-family: 'Proxima Semibold';
    font-weight: 600;
    text-shadow: var(--t-glow-green-light);
}

/* Filter */
#filters {
    display: flex;
    gap: 0.75rem;
    width: fit-content;
}

button.filter {
    text-transform: uppercase;
    background-color: var(--neutral-black);
}