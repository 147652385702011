/* Main Styles */
* {
    box-sizing: border-box;
}

section.dark {
    background-color: var(--transparent-black-2);
}

section.container {
    display: flex;
    justify-content: space-between;
    gap: 8rem;
}

section.container.middle {
    justify-content: center;
    text-align: center;
}

section.container.align {
    align-items: center;
}

section h4.right {
    align-self: flex-end;
    text-align: right;
}

section h4.middle {
    text-align: center;
}

section h4.subtitle {
    max-width: 35rem;
}

section.research-content h5.light {
    margin-bottom: 0;
}

.em {
    font-weight: 600;
}

section.intro-container,
section.research-container,
section.competitive-content,
section.qualitative-content,
section.subtitle-container {
    display: flex;
    flex-direction: column;
    gap: 8rem;
}

#taski section.intro-container {
    gap: 0;
}

section ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

section.facts {
    max-width: 20%;
}

section.facts.main-features {
    max-width: 50%;
}

section.facts ul li,
section.needs-frustrations ul li,
section.moscow-method ul li,
section.insights ul li {
    list-style-type: disc;
}

section.graphic {
    width: 40%;
}

section.graphic figure img {
    width: 100%;
}

section.graphic figure figcaption {
    font-size: 1.5rem;
    text-align: center;
}

/* Title Section */
.casestudy .tagline {
    text-align: left;
}
.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title-container img {
    width: 50%;
}

.title-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.title-headlines {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

article.casestudy h1 {
    font-size: 6rem;
}

.project-categories {
    display: flex;
    flex-direction: column;
}

.project-categorie {
    display: flex;
    width: fit-content;
    background-color: var(--transparent-black-2);
    padding: 0.75rem 1.25rem;
    margin-bottom: 0.75rem;
    border-radius: 1.5rem;
}

section.intro-text,
section.research-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

section.intro-text {
    width: 50%;
}


/* Introduction */
section.intro-facts {
    width: 70%;
}

section.intro-facts ul li p {
    display: inline;
}

section.intro-text p {
    width: 100%;
}

/* Research */
section.research-container {
    display: flex;
}

section.research-content,
section.quantitative-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
}

section.research-text {
    display: flex;
    width: 50%;
}

/* Lean UX */
.canvas-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin: 1rem;
}

.canvas-section {
    padding: 1rem;
    /* Adjust the color based on the actual design */
    border-radius: 5px;
    background-color: var(--transparent-black);
}
.canvas-section hr {
    border: 1px solid var(--transparent-black);
}
/* Competitive */
#retrovrs .competitive-content .container {
    flex-direction: column;
}

table {
    margin-block: 2rem;
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    text-align: center;
    padding: 0.5rem;
    border-bottom: 0.0625rem solid var(--transparent-black);
}

td.checkmark {
    text-shadow: var(--t-glow-green-medium);
}

section.app-icons {
    display: flex;
    max-width: 100%;
    margin: 4rem;
    align-items: center;
    justify-content: space-between;
}

section.app-icons .app-icon {
    width: 15%;
}

section.competitive-facts {
    display: flex;
    justify-content: center;
    gap: 4rem;
}

/* Quantitative */
section.container.survey {
    flex-direction: column;
    width: 100%;
    padding-block: 5rem;
    justify-content: center;
    align-items: center;
}

section.survey-results {
    width: 60%;
}

section.survey-results h3 {
    margin-block: 3rem;
    text-align: center;
}

section.survey {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h5.responses {
    margin-bottom: 2rem;
}

.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 40rem;
    background-color: var(--transparent-black);
    backdrop-filter: var(--glass-blur);
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin: 0.75rem 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: translateY(-0.5rem);
}

.card p {
    margin: 0.5rem;
}

.card .percentage {
    font-size: 2rem;
    font-weight: bold;
}

section.competitive .container .graphic figure {
    display: flex;
    flex-direction: column;
    align-items: center;
}

section.competitive svg {
    filter: var(--s-glow-green-2);
    align-self: center;
    margin: 2rem;
}

/* Qualitative */
section.qualitative-quotes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6rem;
    max-width: 60rem;
    text-align: start;
}

section.qualitative-quotes .quotes {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    padding: 3rem;
    background-color: var(--transparent-black);
    backdrop-filter: var(--glass-blur);
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    gap: 1rem;
}

section.qualitative-quotes .quotes:hover {
    transform: translateY(-0.5rem);
}

section.qualitative-quotes .quotes p {
    font-size: 1.25rem;
}

/* Empathy Map */
.empathy-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

section.empathy-content .container {
    flex-direction: column;
}

.empathy-map {
    position: relative;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    max-width: 60rem;
}

.empathy {
    position: relative;
    padding: 3rem;
    z-index: 1;
}

.does {
    grid-column: 1;
    grid-row: 1;
}

.hear {
    display: flex;
    flex-direction: column;
    text-align: right;
    grid-column: 2;
    grid-row: 1;
}

.see {
    display: flex;
    flex-direction: column;
    text-align: right;
    grid-column: 2;
    grid-row: 2;
}

.say {
    grid-column: 1;
    grid-row: 2;
}

section.pain-gain {
    display: grid;
    grid-template-rows: 1fr;
    gap: 2rem;
}

section.pain-gain ul li {
    list-style-type: disc;
}

.pain {
    background-color: var(--transparent-black);
    backdrop-filter: var(--glass-blur);
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    grid-column: 1;
    grid-row: 1;
}

.gain {
    background-color: var(--transparent-black);
    backdrop-filter: var(--glass-blur);
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    grid-column: 2;
    grid-row: 1;
}

.user-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--transparent-black);
    filter: var(--s-glow-green-2);
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

/* Listen-Styling */
.empathy ul {
    display: flex;
}

.empathy h2 {
    margin-bottom: 20px;
}

/* User Persona */
section.persona-content {
    display: flex;
}

section.persona-content section.container {
    gap: 10%;
}

section.persona-container .container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

section.persona-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    width: 40%;
}

img.persona-img {
    width: 100%;
    border-radius: 200rem;
}

section.persona-infos {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 4rem;
}

section.needs-frustrations {
    display: flex;
    flex-direction: row;
    gap: 4rem;
}

/* User Journey Map */
section.ujm-content .container {
    flex-direction: column;
    align-items: center;
}

section.ujm {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    max-width: 80rem;
    padding-top: 5rem;
}

section.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

section.box-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    height: 30rem;
    border-top: 0.125rem solid var(--transparent-green);
    border-bottom: 0.125rem solid var(--transparent-green);
    justify-content: space-between;
}

section.box-content h6 {
    width: 100%;
    padding: 1rem;
}

section.persona-intro {
    border-right: 0.125rem solid var(--transparent-green);
    padding-inline: 1rem;
    text-align: center;
}

section.persona-intro h5 {
    margin-bottom: 0;
    margin-top: 1rem;
    text-align: center;
}

section.persona-intro img {
    width: 100%;
    height: auto;
    border-radius: 30rem;
}

section.after-work,
section.evening,
section.late-evening,
section.late-night,
.phase1, .phase2, .phase3, .phase4, .phase5 {
    max-width: 25rem;
    border-right: 0.125rem solid var(--transparent-green);
}

.emoji {
    width: 3rem;
}

#retrovrs .emoji {
    width: 4rem;
}

section.box h6.quote {
    text-align: center;
    background-color: var(--transparent-black);
    border-radius: 2rem;
}

ul.opportunities {
    padding-inline: 2rem;
    padding-bottom: 2rem
}

ul.opportunities li {
    list-style-type: disc;
}

/* Problem Statement */
section.section.problemstatement {
    min-height: 50rem;
}

section.problemstatement-container {
    display: flex;
    flex-direction: column;
    margin: 5rem;
}

section.problemstatement-container h4 {
    align-items: center;
    justify-content: center;
    text-align: center;

}

/* Moscow Method */
section.container.moscow {
    flex-direction: column;
}

section.moscow-method {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 70rem;
    align-self: center;
}

section.moscow-method section {
    padding: 3rem;
}

.must {
    border-bottom: 0.25rem solid var(--transparent-green);
    border-right: 0.25rem solid var(--transparent-green);
}

.could {
    border-bottom: 0.25rem solid var(--transparent-green);
}

.should {
    border-right: 0.25rem solid var(--transparent-green);
}

/* Heuristic Analysis */
#retrovrs .heuristic {
    flex-direction: column;
}

.feedback-section {
    display: flex;
    gap: 3rem;
}

.feedback-block {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.feedback-block img {
    min-width: 5rem;
    max-width: 12rem;
}

.feedback-texts {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* MVP Statement */
section.mvp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem;
}

/* Concept / Usability Tests */
section.concept-content .container,
section.usability-content .container {
    flex-direction: column;
}

section.concept-test,
section.usability-test {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
}

section.concept-test section,
section.usability-test section {
    display: flex;
    gap: 4rem;
    align-items: center;
    max-width: 50rem;
}

section.concept-test section.personalized,
section.usability-test section.personalized {
    flex-direction: row-reverse;
    text-align: right;
}

section.concept-test img,
section.usability-test img {
    width: 25%;
    height: auto;
}

/* Visual Competitor Analysis */
section.vca-content .container {
    flex-direction: column;
}

section.vca-container {
    display: flex;
    width: 100%;
    align-items: center;
}

section.vca-container h4 {
    width: 100%;
    align-self: flex-start;
}

section.vca-tiles {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
}

section.vca-tiles img {
    width: 100%;
    max-width: 50rem;
    height: auto;
}

section.insights-container {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    gap: 2rem;
    box-sizing: border-box;
    align-items: center;
}

section.insights-container h3 {
    width: 100%;
}

section.insights {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
}

section.insight {
    display: flex;
    flex-direction: column;
    max-width: 15rem;
}

section.insights ul {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

section.insights h5 {
    margin-bottom: 0;
}

/* Moodboard & Style Tile */
section.mood-style-content {
    gap: 8rem;
}

section.mood-style-content .tiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10rem;
}

section.mood-style-content .container {
    max-width: 70rem;
}

section.mood-style-content img {
    width: 100%;
}