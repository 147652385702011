.profession-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(2%);
    align-self: stretch;
}

.profession-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.profession-text {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 0 0;
}

.profession {
    display: flex;
    padding: 3rem;
    max-width: 15.625rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    flex: 1 0 0;
    align-self: stretch;
    background-color: var(--transparent-black);
    border-radius: 1rem;
    backdrop-filter: var(--glass-blur);
}

.profession-icon {
    display: flex;
    width: 3.5rem;
    height: 3.5rem;
    justify-content: center;
    align-items: center;
    background-color: rgba(238, 219, 218, 0.05);
    border-radius: 20rem;
    box-shadow: var(--glass-base);
    backdrop-filter: var(--glass-blur);
}

section.profession-div label {
    text-align: center;
}