:root {
    /* Colors */
    --primary-red: #EE2E46;
    --primary-red-2: #ffcacf;
    --secondary-green-1: #48E5C2;
    --secondary-green-2: #CAFFF3;
    --neutral-black: #141414;
    --neutral-white-1: #F6F0F0;
    --neutral-white-2: #C9C0BF;

    /* rgb */
    --primary-red-rgb: 238, 46, 70;
    --secondary-green-1-rgb: 72, 229, 194;
    --secondary-green-2-rgb: 202, 255, 243;
    --neutral-black-rgb: 20, 20, 20;
    --neutral-white-1-rgb: 246, 240, 240;
    --neutral-white-2-rgb: 201, 192, 191;
    --transparent-black: rgba(238, 219, 218, 0.05);
    --transparent-black-2: #141414a0;
    --transparent-black-3: #141414bd;
    --transparent-black-4: #141414fa;
    --transparent-white: #f6f0f091;
    --transparent-white-2: #f6f0f027;
    --transparent-red: #e65b6b15;
    --transparent-green: #7cead213;

    /* Glass Effect */
    --glass-blur: blur(16px);
    --glass-blur-2: blur(4px);

    /* Glows - Text */
    /* green */
    --t-glow-green-strong: 0px 0px 25px var(--neutral-black), 0px 0px 10.4px var(--secondary-green-1), 0px 0px 106.8px var(--secondary-green-1), 0px 0px 4px var(--secondary-green-1), 0px 0px 16px var(--secondary-green-1);
    --t-glow-green-medium: 0px 0px 25px var(--neutral-black), 0px 0px 13.8px rgba(var(--secondary-green-1-rgb), 0.80), 0px 0px 40px rgba(var(--secondary-green-1-rgb), 0.80), 0px 0px 4px var(--secondary-green-1);
    --t-glow-green-light: 0px 0px 25px var(--neutral-black), 0px 0px 13.8px rgba(var(--secondary-green-1-rgb), 0.80), 0px 0px 12.8px rgba(var(--secondary-green-1-rgb), 0.10), 0px 0px 4px var(--secondary-green-1);

    /* red */
    --t-glow-red-strong: 0px 0px 10px rgba(var(--primary-red-rgb), 0.25), 0px 0px 5px rgba(var(--primary-red-rgb), 0.75);

    --t-glow-red-medium: 0px 0px 10px rgba(var(--primary-red-rgb), 0.10), 0px 0px 30px rgba(var(--primary-red-rgb), 0.30), 0px 0px 4px var(--primary-red);

    --t-glow-red-light: 0px 0px 10.5px rgba(var(--primary-red-rgb), 0.15), 0px 0px 4px rgba(var(--primary-red-rgb), 0.40);

    /* white */
    --t-glow-white-strong: 0px 0px 25px var(--neutral-black), 0px 0px 10.4px var(--neutral-white-1), 0px 0px 106.8px var(--neutral-white-1), 0px 0px 4px var(--neutral-white-1), 0px 0px 16px var(--neutral-white-1);
    --t-glow-white-medium: 0px 0px 25px var(--neutral-black), 0px 0px 8px rgba(var(--neutral-white-1-rgb), 0.50), 0px 0px 50px rgba(var(--neutral-white-1-rgb), 0.25), 0px 0px 9.6px var(--neutral-white-1), 0px 0px 4px rgba(var(--neutral-white-1-rgb), 0.20);
    --t-glow-white-light: 0px 0px 25px var(--neutral-black), 0px 0px 10.5px var(--neutral-white-2), 0px 0px 50px rgba(var(--neutral-white-2-rgb), 0.20), 0px 0px 2px var(--neutral-white-2);

    /* Glows - Box */
    /* green */
    --b-glow-green-strong: 0px 0px 25px 0px var(--neutral-black), 0px 0px 10.4px 0px var(--secondary-green-1), 0px 0px 106.8px 0px var(--secondary-green-1), 0px 0px 4px 0px var(--secondary-green-1), 0px 0px 16px 0px var(--secondary-green-1), inset 0px 0px 16px 0px var(--secondary-green-1);
    --b-glow-green-medium: 0px 0px 25px 0px var(--neutral-black), 0px 0px 13.8px 0px rgba(var(--secondary-green-1-rgb), 0.80), 0px 0px 40px 0px rgba(var(--secondary-green-1-rgb), 0.80), 0px 0px 4px 0px var(--secondary-green-1), inset 0px 0px 10px 0px var(--secondary-green-1);
    --b-glow-green-light: 0px 0px 25px 0px var(--neutral-black), 0px 0px 13.8px 0px rgba(var(--secondary-green-1-rgb), 0.80), 0px 0px 12.8px 0px rgba(var(--secondary-green-1-rgb), 0.10), 0px 0px 4px 0px var(--secondary-green-1), inset 0px 0px 10px 0px var(--secondary-green-1);

    /* red */
    --b-glow-red-strong: 0px 0px 25px 0px var(--neutral-black), 0px 0px 10.4px 0px var(--primary-red), 0px 0px 11.6px 0px rgba(var(--primary-red-rgb), 0.50), 0px 0px 4px 0px var(--primary-red), 0px 0px 16px 0px var(--primary-red), inset 0px 0px 16px 0px var(--primary-red);
    --b-glow-red-medium: 0px 0px 25px 0px var(--neutral-black), 0px 0px 10.4px 0px var(--primary-red), 0px 0px 60px 0px rgba(var(--primary-red-rgb), 0.50), 0px 0px 4px 0px var(--primary-red), inset 0px 0px 10px 0px var(--primary-red);
    --b-glow-red-light: 0px 0px 25px 0px var(--neutral-black), 0px 0px 10.5px 0px rgba(var(--primary-red-rgb), 0.30), 0px 0px 50px 0px rgba(var(--primary-red-rgb), 0.40), 0px 0px 4px 0px var(--primary-red), inset 0px 0px 10px 0px var(--primary-red);

    /* white */
    --b-glow-white-strong: 0px 0px 25px 0px var(--neutral-black), 0px 0px 10.4px 0px var(--neutral-white-1), 0px 0px 106.8px 0px var(--neutral-white-1), 0px 0px 4px 0px var(--neutral-white-1), 0px 0px 16px 0px var(--neutral-white-1), inset 0px 0px 16px 0px var(--neutral-white-1);
    --b-glow-white-medium: 0px 0px 25px 0px var(--neutral-black), 0px 0px 8px 0px rgba(var(--neutral-white-1-rgb), 0.50), 0px 0px 50px 0px rgba(var(--neutral-white-1-rgb), 0.25), 0px 0px 9.6px 0px var(--neutral-white-1), 0px 0px 4px 0px rgba(var(--neutral-white-1-rgb), 0.20), inset 0px 0px 10px 0px var(--neutral-white-1);
    --b-glow-white-light: 0px 0px 25px 0px var(--neutral-black), 0px 0px 10.5px 0px var(--neutral-white-2), 0px 0px 50px 0px rgba(var(--neutral-white-2-rgb), 0.20), 0px 0px 2px 0px var(--neutral-white-2), inset 0px 0px 10px 0px var(--neutral-white-2);
    
    /* Glow svg */
    --s-glow-green: 
    drop-shadow(0px 0px 3px var(--secondary-green-1))
    drop-shadow(0px 0px 3px var(--secondary-green-1));

    --s-glow-green-2: 
    drop-shadow(0px 0px 8px var(--secondary-green-1));

    --s-glow-red:
        drop-shadow(0px 0px 4px var(--primary-red))
        drop-shadow(0px 0px 8px rgba(var(--primary-red-rgb), 0.40))
        drop-shadow(0px 0px 2px var(--primary-red));

    /* Shadow */
    /* black */
    --shadow-black: 0px 0px 0.7px var(--neutral-black);
}

/* Klassen */
.primary-red {
    color: var(--primary-red);
   }
   
.secondary-green-1 {
    color: var(--secondary-green-1);
}
   
.secondary-green-2 {
    color: var(--secondary-green-2);
}
   
.neutral-black {
    color: var(--neutral-black);
}
   
.neutral-white-1 {
    color: var(--neutral-white-1);
}
   
.neutral-white-2 {
    color: var(--neutral-white-2);
}



.t-glow-red-l {
    text-shadow: var(--t-glow-red-light); 
}

.t-glow-red-m {
    text-shadow: var(--t-glow-red-medium);
}

.t-glow-red-s {
    text-shadow: var(--t-glow-red-strong);
}