@font-face {
  font-family: "Proxima Thin";
  src: url("../fonts/proxima-nova/proxima_thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Light";
  src: url("../fonts/proxima-nova/proxima_light.otf") format("opentype");

}
@font-face {
  font-family: "Proxima Regular";
  src: url("../fonts/proxima-nova/proxima_regular.otf") format("opentype");

}
@font-face {
  font-family: "Proxima Medium";
  src: url("../fonts/proxima-nova/proxima_medium.otf") format("opentype");

}
@font-face {
  font-family: "Proxima Semibold";
  src: url("../fonts/proxima-nova/proxima_semibold.otf") format("opentype");

}
@font-face {
  font-family: "Proxima Bold";
  src: url("../fonts/proxima-nova/proxima_bold.otf") format("opentype");

}
@font-face {
  font-family: "Proxima Extrabold";
  src: url("../fonts/proxima-nova/proxima_extrabold.otf") format("opentype");

}
@font-face {
  font-family: "Proxima Black";
  src: url("../fonts/proxima-nova/proxima_black.otf") format("opentype");

}