#home div {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.tagline {
    width: 100%;
    color: var(--primary-red);
    text-shadow: var(--t-glow-red-strong);
}

