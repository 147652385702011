/* ====== Extra kleine Geräte (Handys, bis zu 600px) ====== */
@media only screen and (max-width: 600px) {

    /* Hauptstile */
    html {
        font-size: 15px;
    }

    section.section {
        padding: 15% 5%;
    }

    aside {
        display: none;
    }

    header {
        padding-inline: 1.25rem;
        padding-block: 0.75rem 0.5rem;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;
        background-color: var(--neutral-black);
    }

    header button.logo svg {
        width: 2rem;
    }

    .scroll-up-button {
        bottom: 2rem;
        right: 2rem;
        width: 3rem;
        height: 3rem;
    }

    /* Hamburger Menu */
    .menu-toggle {
        display: block;
    }

    .menu-toggle svg {
        width: 1.8rem;
    }

    .main-nav {
        display: none;
    }

    .main-nav.open {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 100%;
        left: 0;
        padding-inline: 2rem;
        padding-bottom: 2rem;
        box-sizing: border-box;
        text-align: center;
        justify-content: space-between;
    }

    .main-nav.open aside {
        display: flex;
        position: static;
        width: 100%;
        box-sizing: border-box;
        align-self: center;
        background-color: transparent;
        padding-bottom: 10vh;
    }

    .main-nav.open aside ul.social-icons {
        flex-direction: row;
    }

    .main-nav.open aside ul li a svg {
        padding-bottom: 0;
    }

    .menu-background {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(20, 20, 20, 0.50);
        backdrop-filter: var(--glass-blur);
        z-index: 999;
    }

    .menu-background.active {
        display: block;
    }

    .main-nav ul {
        width: 100%;
        gap: 10%;
        flex-direction: column;
        background-color: transparent;
        backdrop-filter: none;
    }

    .main-nav ul.nav-ul li {
        width: 100%;
        font-size: 1.2rem;
    }

    a#cv-header {
        display: none;
    }

    /* Home Styles */
    h1.bold {
        font-size: 4.2rem;
    }

    .tagline {
        text-shadow: var(--t-glow-red-light);
    }

    h2.section-headline,
    h2.section-headline.right {
        margin-bottom: 2rem;
        text-align: left;
    }

    .hardskill,
    .softskill {
        text-shadow: none;
    }

    /* Profession Section */
    .profession-container {
        flex-direction: column;
        gap: 2rem;
    }

    section.profession {
        max-width: 100%;
    }

    .profession {
        padding: 3rem;
        gap: 1rem;
        align-self: center;
    }

    /* Work Section */
    #filters {
        flex-direction: row;
    }

    section.projects-1,
    section.projects-2 {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-flow: row;
    }

    .project-1,
    .project-2,
    .project-3,
    .project-4,
    .other-projects {
        grid-column: span 1;
    }

    .project-3,
    .project-4 {
        grid-row: span 1;
    }

    figcaption.project-caption {
        background-color: var(--transparent-black-3);
        backdrop-filter: var(--glass-blur-2);
        opacity: 0;
        transition: opacity 0.7s ease;
    }

    figcaption.is-visible {
        opacity: 1;
    }

    /* Skill Section */
    .pill {
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
    }

    p.all {
        font-size: 0.7rem;
        font-weight: bold;
    }

    .skills-items {
        gap: 0.25rem;
    }

    /* About Section */
    section.about-headline {
        flex-direction: row;
    }

    section.about-img {
        display: none;
        align-items: center;
    }

    section.about-img-mobile {
        display: flex;
        align-items: center;
        align-items: flex-start;
    }

    section.about-img-mobile figure img {
        max-width: 7rem;
    }

    section.about-content {
        width: 100%;
    }

    article.about-container {
        gap: 2rem;
    }

    /* Tools Section */
    article.tools {
        flex-direction: column;
        align-items: center;
    }

    .tools-text-container {
        width: 100%;
    }

    .tools-box-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding-inline: 0;

        width: 80%;
    }

    .tool-icon {
        width: 4rem;
        height: 4rem;
    }

    .tool-icon svg {
        width: 2.5rem;
        height: 2.5rem;
    }

    #vs-code svg {
        width: 2rem;
        height: 2rem;
    }



    /* Contact Section */
    #contact {
        gap: 1rem;
    }

    #contact h1 {
        font-size: 4rem;
    }

    /* Footer Section */
    footer {
        padding: 5rem 4rem;
        justify-content: center;
        align-items: center;
    }

    .copyright-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        align-items: center;
    }

    .copyright-box section.logo {
        width: 72px;
        height: auto;
    }

    /* Impressum */
    #imprint {
        width: 100vw;
        text-align: center;
    }

    /* Case Studies */
    article.casestudy section.section {
        padding: 4rem 1rem;
    }

    article.casestudy li {
        margin-left: 1rem;
    }

    article.casestudy h1 {
        font-size: 4rem;
    }

    article.casestudy h3 {
        font-size: 1.5rem;
    }

    section.container {
        gap: 2rem;
    }

    .title-container {
        flex-direction: column;
    }

    .title-content,
    .title-headlines,
    .project-categories {
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    section.intro-container,
    section.research-container,
    section.competitive-content,
    section.qualitative-content,
    section.subtitle-container {
        gap: 2rem;
    }

    /* Ritualzz */
    section.intro-container .container {
        flex-direction: column;
        gap: 2rem;
    }

    section.intro-text {
        width: 100%;
    }

    section.intro-text p {
        width: 100%;
    }

    section.facts {
        max-width: 60%;
    }

    section.intro-facts {
        align-self: center;
    }

    section.research-content .container {
        flex-direction: column;
    }

    section.research-text {
        width: 100%;
    }

    .competitive section.container {
        gap: 0;
    }

    .competitive-content,
    .competitive-content h5 {
        font-size: 0.8rem;
    }

    .competitive-content td {
        padding-inline: 0;
    }

    .competitive-content th,
    .competitive-content td {
        padding-inline: 0;
        width: 5%;
    }

    section.app-icons {
        margin: 0.5rem;
    }

    section.competitive-facts {
        gap: 2rem;
    }

    .competitive section.container {
        margin-block: 1rem;
    }

    .competitive-content .container.align {
        gap: 1rem;
    }

    section.competitive svg {
        margin: 0;
        width: 5rem;
    }

    section.qualitative-quotes {
        grid-gap: 1rem;
    }

    section.qualitative-quotes .quotes {
        padding: 1rem;
    }

    section.qualitative-quotes .quotes p {
        font-size: 1rem;
    }

    section.empathy-map {
        gap: 2rem;
    }

    .user-icon {
        width: 6rem;
        height: 6rem;
    }

    section.pain-gain {
        gap: 0.5rem;
    }

    section.empathy-map li {
        margin-left: 0;
    }

    section.empathy {
        padding: 0;
    }

    section.empathy.pain,
    section.empathy.gain {
        padding: 1rem;
    }

    section.empathy ul {
        gap: 0;
    }

    section.ujm {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 0;
    }

    section.midnight, .phase6 {
        border-right: 0.125rem solid var(--transparent-green);
    }

    section.box h6.quote {
        font-size: 0.8rem;
        font-family: 'Proxima Light';
    }

    ul.opportunities {
        padding-inline: 1rem;
        padding-bottom: 1rem;
    }

    section.evening,
    section.late-night {
        border-left: 0.125rem solid var(--transparent-green);
    }

    section.persona-show {
        width: 100%;
    }

    section.persona-infos {
        width: 100%;
    }

    section.persona-content .container {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    section.persona-discription {
        align-items: center;
        text-align: center;
    }

    section.persona-intro, .phase1, .phase3, .phase5 {
        border-left: 0.125rem solid var(--transparent-green);
    }

    section.needs-frustrations {
        gap: 1.5rem;
    }

    section.moscow-method section {
        padding: 0;
        padding-block: 1.5rem;
    }

    section.moscow-method li p {
        font-size: 1.2rem;
    }

    section.moscow-method .must,
    section.moscow-method .should {
        padding-right: 1rem;
    }

    section.moscow-method .could,
    section.moscow-method .wont {
        padding-left: 1rem;
    }

    section.mvp-container {
        margin: 2rem;
    }

    section.mvp-container h4 {
        font-size: 1.2rem;
    }

    section.concept-test {
        gap: 6rem;
    }

    section.concept-test section,
    section.usability-test section {
        margin-inline: 1rem;
        min-height: 45vh;
        gap: 1rem;
    }

    section.concept-test img,
    section.usability-test img {
        width: 40%;
    }

    section.vca-container {
        align-items: flex-start;
    }

    section.insights-container {
        margin-top: 1.5rem;
    }

    section.insights {
        gap: 1rem;
        flex-direction: column;
    }

    section.insights h5,
    section.insights p {
        font-size: 1.1rem;
        gap: 1rem;
    }

    section.insight {
        max-width: 100%;
    }

    section.mood-style-content {
        gap: 1rem;
    }

    section.mood-style-content .tiles {
        gap: 4rem;
    }

    section.prototype-content {
        gap: 1rem;
    }

    section.box {
        border-top: 0.125rem solid var(--transparent-green);
        border-bottom: 0.125rem solid var(--transparent-green);
        padding-top: 1rem;
    }

    section.prototype-video iframe {
        width: 20rem;
        height: 39rem;
    }

    /* Retrovrs */
    .canvas-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .contact-box {
        align-items: center;
        justify-content: center;
    }

    .contact-box svg {
        display: none;
    }

    .feedback-section {
        flex-direction: column;
    }

}

/* ====== Kleine Geräte (Tablets, 600px und darüber) ====== */
@media only screen and (min-width: 600px) and (max-width: 767px) {

    /* Main Styles */
    html {
        font-size: 16px;
    }

    section.section {
        padding: 7% 12%;
    }

    header {
        padding: 1rem 1.5rem;
    }

    header button.logo svg {
        width: 2.5rem;
    }

    aside {
        display: none;
    }

    h1.bold {
        font-size: 6rem;
    }

    /* Hamburger Menu */
    .menu-toggle {
        display: block;
    }

    .main-nav {
        display: none;
    }

    .main-nav.open {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 100%;
        left: 0;
        padding-inline: 2rem;
        padding-bottom: 2rem;
        box-sizing: border-box;
        text-align: center;
        justify-content: space-between;
    }

    .main-nav.open aside {
        display: flex;
        position: static;
        width: 100%;
        box-sizing: border-box;
        align-self: center;
        background-color: transparent;
        padding-bottom: 20vh;
    }

    .main-nav.open aside ul.social-icons {
        flex-direction: row;
    }

    .main-nav.open aside ul li a svg {
        padding-bottom: 0;
    }

    .menu-background {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(20, 20, 20, 0.50);
        backdrop-filter: var(--glass-blur);
        z-index: 999;
    }

    .menu-background.active {
        display: block;
    }

    .main-nav ul {
        width: 100%;
        gap: 10%;
        flex-direction: column;
        background-color: transparent;
        backdrop-filter: none;
    }

    .main-nav ul.nav-ul li {
        width: 100%;
    }

    a#cv-header {
        display: none;
    }

    /* Profession Section */
    .profession {
        padding: 2rem;
        gap: 1rem;
    }

    .profession-container {
        flex-direction: column;
        gap: 2rem;
    }

    section.profession {
        max-width: 20rem;
    }

    .profession {
        padding: 3rem;
        gap: 1rem;
        align-self: center;
    }

    /* Work Section */
    section.projects-1,
    section.projects-2 {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-flow: row;
    }

    .project-1,
    .project-2,
    .project-3,
    .project-4,
    .other-projects {
        grid-column: span 1;
    }

    .project-3,
    .project-4 {
        grid-row: span 1;
    }

    figcaption.project-caption {
        background-color: var(--transparent-black-3);
        backdrop-filter: none;
        opacity: 0;
        transition: opacity 0.7s ease;
    }

    figcaption.is-visible {
        opacity: 1;
    }

    /* About Section */
    section.about-img {
        align-items: flex-start;
    }

    section.about-img figure img {
        padding-right: 0;
        max-width: 10rem;
    }

    article.about-container {
        gap: 2rem;
    }

    /* Contact Section */
    #contact {
        gap: 1rem;
    }

    #contact h1 {
        font-size: 4rem;
    }

    /* Footer Section */
    footer {
        padding: 2rem 6rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        align-self: flex-end;
    }

    .footer-links {
        flex-direction: column;
        gap: 3rem;
        align-items: center;
    }

    .copyright-box {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        align-items: center;
    }

    .copyright-box section.logo {
        width: 72px;
        height: auto;
    }

    /* Case Studies */
    .title-container {
        flex-direction: column;
    }

    .title-content,
    .title-headlines,
    .project-categories {
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    /* Ritualzz */
    section.ujm {
        grid-template-columns: repeat(3, 1fr);
    }

    section.evening,
    section.midnight,
    .phase6 {
        border-right: 0.125rem solid var(--transparent-green);
    }

    section.persona-intro,
    section.late-evening,
    .phase1, .phase3, .phase5 {
        border-left: 0.125rem solid var(--transparent-green);
    }

    section.box {
        border-top: 0.125rem solid var(--transparent-green);
        border-bottom: 0.125rem solid var(--transparent-green);
        padding-top: 1rem;
    }

    section.box-content {
        height: 22rem;
    }

    /* Retrovrs */
    .canvas-container {
        grid-template-columns: repeat(1, 1fr);
    }

    #retrovrs section.ujm {
        grid-template-columns: repeat(2, 1fr);
    }

    /* Impressum */
    #imprint {
        width: 100vw;
        text-align: center;
    }

    .contact-box {
        align-items: center;
        justify-content: center;
    }

    .contact-box svg {
        display: none;
    }
}

/* ====== Mittlere Geräte (Tablets/Laptops, 768px und darüber) ====== */
@media only screen and (min-width: 768px) and (max-width: 991px) {

    /* Hauptstile */
    html {
        font-size: 15px;
    }

    /* section.section {
        padding: 2rem 8rem;
    } */

    aside {
        display: none;
    }

    h1.bold {
        font-size: 7rem;
    }

    /* Profession Section */
    .profession {
        padding: 2rem;
        gap: 1rem;
    }

    .profession-container {
        flex-direction: column;
        gap: 2rem;
    }

    section.profession {
        max-width: 20rem;
    }

    .profession {
        padding: 4rem;
        gap: 1rem;
        align-self: center;
    }

    /* Work Section */
    section.projects-1 {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-flow: row;
    }

    section.projects-2 {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
    }

    .project-1,
    .project-2,
    .project-3,
    .project-4,
    .other-projects {
        grid-column: span 1;
        grid-row: span 1;
    }

    figure:hover figcaption {
        display: flex;
        opacity: 1;
    }

    /* Footer */
    footer {
        padding: 4rem 8rem;
    }


    /* About Section */
    section.about-img figure img {
        padding-right: 0;
    }

    section.about-img figure img {
        max-width: 12rem;
    }

    article.about-container {
        gap: 2rem;
    }

    /* Case Studies */
    .title-container {
        flex-direction: column;
    }

    .title-content,
    .title-headlines,
    .project-categories {
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    /* Ritualzz */
    section.ujm {
        grid-template-columns: repeat(3, 1fr);
    }

    section.evening,
    section.midnight,
    .phase6 {
        border-right: 0.125rem solid var(--transparent-green);
    }

    section.persona-intro,
    section.late-evening,
    .phase1, .phase3, .phase5 {
        border-left: 0.125rem solid var(--transparent-green);
    }

    section.box {
        border-top: 0.125rem solid var(--transparent-green);
        border-bottom: 0.125rem solid var(--transparent-green);
        padding-top: 1rem;
    }

    section.box-content {
        height: 22rem;
    }

    /* Retrovrs */
    .canvas-container {
        grid-template-columns: repeat(2, 1fr);
    }

    #retrovrs section.ujm {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* ====== Normale Geräte (Laptops/Desktops, 992px und darüber) ====== */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

    /* Hauptstile */
    html {
        font-size: 16px;
    }

    /* section.section {
        padding: 3rem 10rem;
    } */


    /* Work Section */
    section.projects-1 {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-flow: row;
    }

    section.projects-2 {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
    }

    .project-1,
    .project-2,
    .project-3,
    .project-4,
    .other-projects {
        grid-column: span 1;
        grid-row: span 1;
    }

    figure:hover figcaption {
        display: flex;
        opacity: 1;
    }

    /* About Section */
    article.about-container {
        gap: 2rem;
    }

    section.about-img figure img {
        padding-right: 0;
    }

    /* Profession Section */
    .profession {
        padding: 2rem;
        gap: 1rem;
    }

    .profession-container {
        flex-direction: column;
        gap: 2rem;
    }

    section.profession {
        max-width: 20rem;
    }

    .profession {
        padding: 4rem;
        gap: 1rem;
        align-self: center;
    }

    /* Case Studies */
    /* Ritualzz */
    section.ujm {
        grid-template-columns: repeat(3, 1fr);
    }

    section.evening,
    section.midnight {
        border-right: 0.125rem solid var(--transparent-green);
    }

    section.persona-intro,
    section.late-evening {
        border-left: 0.125rem solid var(--transparent-green);
    }

    section.box {
        border-top: 0.125rem solid var(--transparent-green);
        border-bottom: 0.125rem solid var(--transparent-green);
        padding-top: 1rem;
    }

    section.box-content {
        height: 22rem;
    }

    /* Retrovrs */
    .canvas-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* ====== Große Geräte (Laptops/Desktops, 1200px und darüber) ====== */
@media only screen and (min-width: 1200px) and (max-width: 1439px) {

    /* Hauptstile */
    html {
        font-size: 16px;
    }

    /* section.section {
        padding: 8rem 10rem;
    } */


    /* Work Section */
    section.projects-1 {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
    }

    section.projects-2 {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: row;
    }

    .project-1,
    .project-2,
    .project-3,
    .project-4,
    .other-projects {
        grid-column: span 1;
        grid-row: span 1;
    }

    figure:hover figcaption {
        display: flex;
        opacity: 1;
    }

    /* Professtion Section */
    .profession {
        padding: 2rem;
    }

    /* About Section */
    article.about-container {
        justify-content: flex-start;
    }

    /* Case Studies */
    /* Ritualzz */
    section.ujm {
        grid-template-columns: repeat(3, 1fr);
    }

    section.evening,
    section.midnight {
        border-right: 0.125rem solid var(--transparent-green);
    }

    section.persona-intro,
    section.late-evening {
        border-left: 0.125rem solid var(--transparent-green);
    }

    section.box {
        border-top: 0.125rem solid var(--transparent-green);
        border-bottom: 0.125rem solid var(--transparent-green);
        padding-top: 1rem;
    }

    section.box-content {
        height: 22rem;
    }

    /* Retrovrs */
    .canvas-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* ====== Sehr große Geräte (sehr große Desktops, 1440px und darüber) ====== */
@media only screen and (min-width: 1440px) {

    /* Hauptstile */
    html {
        font-size: 16px;
    }

    /* section.section {
        padding: 8rem 22rem;
    } */

    h1.bold {
        font-size: 8rem;
    }

    /* Work Section */
    figure:hover figcaption {
        display: flex;
        opacity: 1;
    }

    /* Profession Section */
    .profession-icon {
        width: 4rem;
        height: 5.5rem;
    }

    /* About Section */
    article.about-container {
        justify-content: flex-start;
        justify-content: space-between;
    }

    /* Tools Section */
    article.tools {
        flex-direction: row;
    }

    .tools-text-container {
        width: 100%;
    }

    .tools-box-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        padding-inline: 0;
    }

    .tool-box {
        margin-bottom: 2rem;
    }

    /* Case Studies */
    
    section.evening,
    .phase3 {
        border-right: 0;
    }

    section.ujm {
        grid-template-columns: 1fr 1fr 1fr;
    }

    /* Retrovrs */
    #retrovrs .emoji {
        width: 6rem;
    }

    /* Footer */
    footer {
        padding: 4rem 22rem;
    }
}

/* ====== Extra große Geräte (extra große Desktops, 1800px und darüber) ====== */
@media only screen and (min-width: 1800px) {

    /* Hauptstile */
    html {
        font-size: 18px;
    }

    section.section {
        padding: 10% 20%;
    }

    header {
        padding: 3rem 5rem;
    }

    aside {
        right: 5rem;
    }

    /* Work Section */
    figure:hover figcaption {
        display: flex;
        opacity: 1;
    }

    /* About Section */
    article.about-container {
        justify-content: space-between;
    }

    section.about-content {
        max-width: 35rem;
    }

    /* Casestudies */
    section.intro-text {
        width: 50%;
    }

    section.intro-facts {
        width: 25%;
    }

    /* Retrovrs */
    section.ujm {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    /* Footer */
    footer {
        padding: 4rem 25rem;
    }

}

div.app {
    width: 100vw;
}

/* section.introduction,  */
/* section.research, */
/* section.competitive, */
/* section.quantitative, */
/* section.qualitative, */
/* section.empathy-content, */
/* section.persona, */
/* section.ujm-content, */
/* section.problemstatement, */
/* section.moscow-content, */
/* section.mvp, */
/* section.concept-content, */
/* section.usability-content, */
/* section.vca-content, */
/* section.mood-style-content, */
/* section.prototype-content { */
/* display: none;
} */