/* Footer Styles */
footer {
    width: 100%;
    height: auto;
    padding: 4rem 12.5rem;
    background: #141414;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    box-sizing: border-box;
}

.copyright-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 2rem;
}

.copyright {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-shadow: var(--t-glow-green-light);
}


section.copyright-box .logo {
    cursor: pointer;
}

section.copyright-box .logo svg {
    filter: var(--s-glow-red);
}

.heart {
    font-size: 0.8rem;
    padding: 0.25rem;
}