/* Typography Styles */

/* Headings/H1 */

h1.bold {
    margin: 0;
    font-family: 'Proxima Black';
    font-size: 8rem;
    /* 128px / 1rem */
    font-style: normal;
    font-weight: 700;
    line-height: 0.75;
    /* 75% / 100% */
    text-transform: uppercase;
}

h1.tagline {
    text-align: center;
}

h1.medium {
    font-family: 'Proxima Medium';
    font-size: 8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.75;
    text-transform: uppercase;
}

h1.light {
    font-family: 'Proxima Light';
    font-size: 8rem;
    font-style: normal;
    font-weight: 300;
    line-height: 0.75;
    text-transform: uppercase;
}

/* Headings/H2 */

h2.bold {
    font-family: 'Proxima Bold';
    font-size: 2rem;
    /* 40px / 1rem */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

h2.tagline {
    text-align: center;
}

h2.medium {
    font-family: 'Proxima Medium';
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

h2.light {
    font-family: 'Proxima Light';
    font-size: 2rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    color: var(--primary-red);
    text-shadow: var(--t-glow-red-medium);
}

/* Headings/H3 */

h3.bold,
h3 span.bold {
    font-family: 'Proxima Bold';
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.05rem;
}

h3.medium {
    font-family: 'Proxima Medium';
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.05rem;
}

h3.light {
    font-family: 'Proxima Light';
    font-size: 2rem;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.05rem;
}

/* Headings/H4 */

h4.bold {
    font-family: 'Proxima Bold';
    font-size: 1.5em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
}

h4.medium {
    font-family: 'Proxima Medium';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25;
}

h4.light {
    font-family: 'Proxima Light';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.25;
}

/* Headings/H5 */

h5.bold {
    font-family: 'Proxima Bold';
    font-size: 1.25rem;
    /* 20px / 1rem */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

h5.medium {
    font-family: 'Proxima Medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

h5.light {
    font-family: 'Proxima Light';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Headings/H6 */

h6.bold {
    font-family: 'Proxima Bold';
    font-size: 1rem;
    /* 1rem / 1rem */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

h6.medium {
    font-family: 'Proxima Medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

h6.light {
    font-family: 'Proxima Light';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Body/P1 */

p.p1-light {
    font-family: 'Proxima Light';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 100;
    line-height: 1.5;
}

p.p1-medium {
    font-family: 'Proxima Medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
}

p.p1-bold,
p span.p1-bold {
    font-family: 'Proxima Semibold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
}

/* Body/P2 */

p.p2-light {
    font-family: 'Proxima Light';
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

p.p2-medium {
    font-family: 'Proxima Medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

p.p2-bold {
    font-family: 'Proxima Extrabold';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* Body/P3 */

p.p3-light {
    font-family: 'Proxima Light';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

p.p3-medium {
    font-family: 'Proxima Regular';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

p.p3-bold {
    font-family: 'Proxima Semibold';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* Label / L1 */

label.l1-light {
    font-family: 'Proxima Light';
    font-size: 1.25rem;
    /* 20px / 1rem */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

label.l1-medium {
    font-family: 'Proxima Medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;


}

label.l1-bold {
    font-family: 'Proxima Bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* L2 */

label.l2-light {
    font-family: 'Proxima Light';
    font-size: 1rem;
    /* 1rem / 1rem */
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    /* 20px / 1rem */
}

label.l2-medium {
    font-family: 'Proxima Medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25;
}

label.l2-bold {
    font-family: 'Proxima Bold';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
}

/* Navigation */
.main-nav ul li,
p.name {
    font-family: 'Proxima Light';
    font-size: 1rem;
    /* 1rem / 1rem */
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.2rem;
    /* 3.2px / 1rem */
    text-transform: uppercase;
}

/* Buttons */
button.small,
a.small {
    font-family: 'Proxima Light';
    font-size: 0.75rem;
    /* 14px / 1rem */
    font-style: normal;
    line-height: 1.25;
    /* 125% / 100% */
    letter-spacing: 0.0625rem;
    /* 1px / 1rem */
}

button.medium,
a.medium {
    font-family: 'Proxima Light';
    font-size: 0.8rem;
    /* 1rem / 1rem */
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.1rem;
}

button.big,
a.big.button {
    font-family: 'Proxima Light';
    font-size: 1rem;
    /* 18px / 1rem */
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.0625rem;
}