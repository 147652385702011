/* Header Styles */

header {
    display: flex;
    padding: 2.5rem 3.125rem;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
}

.main-nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex: 1 0 0;
    box-sizing: border-box;
    background-color: rgba(20, 20, 20, 0.50);
    backdrop-filter: var(--glass-blur);
    padding: 1.5rem 3rem;
    border-radius: 0.5rem;
}

.main-nav ul li.active,
p.name {
    font-family: 'Proxima Semibold';
    font-weight: 600;
    text-shadow: var(--t-glow-green-medium);
}

.main-nav {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.nav-visible {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
}

.nav-hidden {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0s linear 0.5s;
}

header svg {
    filter: var(--s-glow-red);
}

/* Hamburger-Menu */
.menu-toggle {
    display: none;
    cursor: pointer;
}





