/* Projekte */
section.projects-1,
section.projects-2 {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
    box-sizing: border-box;
    gap: 0.5rem;
    padding-top: 0.5rem;
}

section.projects-2 {
    grid-auto-flow: row;
    grid-template-rows: 1fr;
    padding-bottom: 1rem;
}

section.projects-1 article figure,
section.projects-2 article figure {
    position: relative;
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

section.projects-1 article figure a img,
section.projects-2 article figure a img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    box-sizing: border-box;
}

.project-1,
.project-2,
.other-projects {
    grid-column: span 1;
    grid-row: span 1;
}

.project-3 {
    grid-column: span 2;
    grid-row: span 2;
}

.project-4 {
    grid-column: span 1;
    grid-row: span 2;
}

figcaption.project-caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: var(--transparent-black-2);
    border-radius: 0.5rem;
    backdrop-filter: var(--glass-blur);
    gap: 0.5rem;
    opacity: 0;
    transition: opacity 0.7s ease;
}

div.description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative
}

p.project-description {
    font-family: 'Proxima Medium';
    font-size: 1.25rem;
    text-align: center;
}

p.project-label {
    display: flex;
    font-family: 'Proxima Medium';
    font-size: 0.8rem;
    text-align: center;
    background-color: var(--secondary-green-2);
    color: var(--neutral-black);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    text-transform: uppercase;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

p.project-categories {
    font-family: 'Proxima Thin';
    font-size: 1rem;
    text-align: center;
    background-color: var(--transparent-black-2);
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
}

/* See More Button */
.btn-container {
    display: flex;
    justify-content: flex-end;
    padding-block: 1rem;
}

.arrow {
    font-size: 1rem;
    line-height: 0;
}