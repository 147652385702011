.contact-box {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin: 1rem;
}

.contact-box svg {
    margin-top: 0.25rem;
    filter: var(--s-glow-green);
}

.contact-label {
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
}

section.connect {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4rem;
    width: 100%;
}

section.connect button {
    text-transform: uppercase;
}

#contact h1 {
    font-size: 6rem;
}