article.about-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4rem;
}

section.about-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50vw;
}

section.about-headline {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

section.about-img {
    display: flex;
    align-items: center;
}

section.about-img-mobile {
    display: none;
    align-items: center;
}

section.about-img figure.img,
section.about-img-mobile figure.img {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}


section.about-img figure img,
section.about-img-mobile figure img {
    max-width: 20rem;
    height: auto;
}

section.button-container {
    margin-top: 2rem;
    display: flex;
}




